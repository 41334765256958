define('ember-js-getting-started/router', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('app', { path: '/app/:application_key' }, function () {
      this.route('email-verification');
      this.route('checkout');
      this.route('success');
    });
  });

  exports.default = Router;
});