define('ember-js-getting-started/services/auth-token-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var checkStorage = function checkStorage() {
    if (localStorage.getItem('ecom-checkout:token') === null) {
      return false;
    }
    return true;
  };

  exports.default = Ember.Service.extend({
    requestService: Ember.inject.service('request-service'),
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),

    login: function login(self, email, pass) {
      Ember.$.ajax({
        url: _environment.default.APP.cfwhatsServiceUrl + '/logins',
        type: 'POST',
        dataType: 'json',
        async: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: '{"email":"' + email + '", "password": "' + pass + '"}',
        success: function success(response) {
          if (response.status === 401) {
            localStorage.setItem('ecom-checkout:token', "");
          } else {
            localStorage.setItem('ecom-checkout:token', response.token);
            self.set('response', response);
          }
        },
        failure: function failure(response) {
          if (response.status === 401) {
            localStorage.setItem('ecom-checkout:token', "");
          } else {
            alert('um erro inesperado');
          }
        },
        error: function error(response) {
          if (response.status === 401) {
            localStorage.setItem('ecom-checkout:token', "");
          } else {
            alert('um erro inesperado');
          }
        }
      });
      return self.get('response');
    },
    logoff: function logoff(self) {
      localStorage.setItem('ecom-checkout:token', "");
      self.get('customerStorageManagerService').remove();
    },
    cleanToken: function cleanToken() {
      localStorage.setItem('ecom-checkout:token', "");
    },
    getLoggedCustomerData: function getLoggedCustomerData(self, auth) {
      Ember.$.ajax({
        url: _environment.default.APP.cfwhatsServiceUrl + '/customers',
        type: 'GET',
        dataType: 'json',
        async: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Auth': auth
        },
        success: function success(response) {
          if (response.status === 401) {
            alert('acesso não autorizado.');
          } else {
            self.set('response', response);
            self.get('customerStorageManagerService').store(response);
          }
        },
        failure: function failure(response) {
          if (response.status === 401) {
            alert('acesso não autorizado.');
          } else {
            alert('um erro inesperado');
          }
        },
        error: function error(response) {
          if (response.status === 401) {
            alert('acesso não autorizado.');
          } else {
            alert('um erro inesperado');
          }
        }
      });
      return self.get('response');
    },
    resetPassword: function resetPassword(self, email) {
      self.get('requestService').request(self, JSON.stringify({ 'email': email }), '/resetPasswords/sendEmail', 'POST', _environment.default.APP.stargetServiceUrl);
    },
    get: function get() {
      if (checkStorage()) {
        return localStorage.getItem('ecom-checkout:token');
      }
    }
  });
});