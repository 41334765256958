define('ember-js-getting-started/services/email-verification-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    requestService: Ember.inject.service('request-service'),

    validate: function validate(email) {
      return this.get('requestService').request(this, null, '/customers/' + email + '/validate', 'GET', _environment.default.APP.sgplServiceUrl);
    }
  });
});