define('ember-js-getting-started/services/cart-storage-manager-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var checkStorage = function checkStorage() {
    if (localStorage.getItem('ecom-checkout:cart') === null) {
      return false;
    }
    return true;
  };

  exports.default = Ember.Service.extend({
    store: function store(cart) {
      //localStorage.setItem('ecom-checkout:cart', JSON.stringify(c));    
      localStorage.setItem('ecom-checkout:cart', cart);
    },
    remove: function remove() {
      localStorage.removeItem('ecom-checkout:cart');
    },
    get: function get() {
      if (checkStorage()) {
        return JSON.parse(localStorage.getItem('ecom-checkout:cart'));
      }
    },
    getItens: function getItens() {
      if (checkStorage()) {
        var retrievedObject = JSON.parse(localStorage.getItem('ecom-checkout:cart'));
        return retrievedObject.itens;
      }
    }
  });
});