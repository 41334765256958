define('ember-js-getting-started/components/success-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cartStorageManagerService: Ember.inject.service('cart-storage-manager-service'),
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),

    cart: function () {
      return this.get('cartStorageManagerService').get();
    }.property('cart'),

    customer: function () {
      return this.get('customerStorageManagerService').get();
    }.property('customer'),

    actions: {
      homeLink: function homeLink() {
        window.location.replace(this.get('cart').homeLink);
      },
      userPanelLink: function userPanelLink() {
        window.location.replace(this.get('cart').userPanelLink);
      }
    }
  });
});