define("ember-js-getting-started/helpers/format-money", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatMoney = formatMoney;


    Number.prototype.formatMoney = function (c, d, t) {
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d === undefined ? "," : d,
            t = t === undefined ? "." : t,
            s = n < 0 ? "-" : "",
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    function formatMoney(params) {
        var value = params[0];
        if (value !== null && value !== undefined && value !== '') {
            return 'R$' + value.formatMoney(2, ',', '.');
        }
    }

    exports.default = Ember.Helper.helper(formatMoney);
});