define('ember-js-getting-started/services/shop-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var createShopPlanBuy = function createShopPlanBuy(self, creditCard) {
    var customer = JSON.stringify(self.get('customerStorageManagerService').get());
    var idProductLicense = self.get('cartStorageManagerService').get().itens[0].idProductLicense;
    var creditCard = JSON.stringify(creditCard);
    var shopPlanBuy = '{"customer":' + customer + ', "idProductLicense": "' + idProductLicense + '","paymentMethodKey":"CREDIT_CARD_RECURRENT", "creditCard":' + creditCard + '}';
    return shopPlanBuy;
  };

  exports.default = Ember.Service.extend({
    requestService: Ember.inject.service('request-service'),
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),
    cartStorageManagerService: Ember.inject.service('cart-storage-manager-service'),

    shop: function shop(self, creditCard) {
      self.set('paymentLoading', true);

      var shopPlanBuy = createShopPlanBuy(self, creditCard);

      Ember.$.ajax({
        url: _environment.default.APP.sgplServiceUrl + '/orders/shop',
        type: 'POST',
        dataType: 'json',
        async: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: shopPlanBuy,
        success: function success(response) {
          self.set('paymentLoading', false);
          self.set('response', response);
        },
        failure: function failure(response) {
          self.set('paymentLoading', false);
          self.set('response', response);
        },
        error: function error(response) {
          self.set('paymentLoading', false);
          self.set('response', response);
        }
      });
      return self.get('response');
    }
  });
});