define('ember-js-getting-started/routes/app/success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    authTokenService: Ember.inject.service('auth-token-service'),

    model: function model() {
      this.get('authTokenService').cleanToken(this);
    }
  });
});