define('ember-js-getting-started/services/customer-storage-manager-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var checkStorage = function checkStorage() {
    if (localStorage.getItem('ecom-checkout:customer') === null) {
      return false;
    }
    return true;
  };

  exports.default = Ember.Service.extend({
    store: function store(customer) {
      localStorage.setItem('ecom-checkout:customer', JSON.stringify(customer));
    },
    storeIsLoggedOn: function storeIsLoggedOn(booleanValue) {
      localStorage.setItem('ecom-checkout:customer-is-logged-on', booleanValue);
    },
    storeIsLoggedOutWithoutRegister: function storeIsLoggedOutWithoutRegister(booleanValue) {
      localStorage.setItem('ecom-checkout:customer-logged-out-without-register', booleanValue);
    },
    storeSelectedEmail: function storeSelectedEmail(email) {
      localStorage.setItem('ecom-checkout:customer-selected-email', email);
    },
    remove: function remove() {
      if (checkStorage()) {
        localStorage.removeItem('ecom-checkout:customer');
      }
    },
    get: function get() {
      if (checkStorage()) {
        return JSON.parse(localStorage.getItem('ecom-checkout:customer'));
      }
    },
    getIsLoggedOn: function getIsLoggedOn() {
      if (checkStorage()) {
        var check = localStorage.getItem('ecom-checkout:customer-is-logged-on');
        if (check === 'true') return true;else return false;
      }
    },
    getIsLoggedOutWithoutRegister: function getIsLoggedOutWithoutRegister() {
      if (checkStorage()) {
        var check = localStorage.getItem('ecom-checkout:customer-logged-out-without-register');
        if (check === 'true') return true;else return false;
      }
    },
    getSelectedEmail: function getSelectedEmail() {
      if (checkStorage()) {
        return localStorage.getItem('ecom-checkout:customer-selected-email');
      }
    }
  });
});