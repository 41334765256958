define('ember-js-getting-started/components/checkout-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var executeConfirmSelectedEmail = function executeConfirmSelectedEmail(self) {
    var isEmail = self.get('utilService').isEmail(self.get('customerSelectedEmail'));

    self.set('isValidSelectedEmail', isEmail);

    if (isEmail === true) {
      self.set('confirmSelectedEmail', self.get('email'));

      self.set('isCofirmedSelectedEmailOut', true);

      if (self.get('isConfirmedSelectedEmail') === false) {
        self.set('isConfirmedSelectedEmail', true);
      } else {
        self.set('isConfirmedSelectedEmail', false);
      }

      try {
        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/customers/' + self.get('customerSelectedEmail') + '/validate',
          type: 'GET',
          dataType: 'json',
          async: false,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: null,
          success: function success(response) {
            if (self.get('customerSelectedEmail') != undefined && self.get('customerSelectedEmail').length > 0 && response !== undefined && response !== null && Object.keys(response).length > 0) {
              self.get('customerStorageManagerService').store(response);
              self.set('customer', response);
              self.set('customerWithoutRegister', false);
              self.set("step1", false);
              self.set("step2", false);
              self.set("step3", true);

              var customer = self.get('customerStorageManagerService').get();
              customer.email = self.get('customerSelectedEmail');

              self.get('customerStorageManagerService').store(customer);
              self.get('customerStorageManagerService').storeIsLoggedOn(false);
            } else {
              var customer = self.get('customerStorageManagerService').get();
              customer.email = self.get('customerSelectedEmail');
              self.get('customerStorageManagerService').store(customer);
              self.$('input#postalCode').focus();
            }
          },
          failure: function failure(response) {
            console.log(response);
            alert('houve um erro inesperado');
          },
          error: function error(response) {
            console.log(response);
            alert('houve um erro inesperado');
          }
        });
      } catch (err) {} finally {
        var cust = self.get('customerStorageManagerService').get();
        self.set('isCustomerBusiness', cust.business);
      }
    }
  };

  var executeFinishStep3 = function executeFinishStep3(self) {
    var shopBuy = null;
    if (self.get('cart.credits') === true || self.get('cart.autoresponder') === true || self.get('cart.machine') === true) {
      shopBuy = createShopCreditBuy(self, self.get('creditCard'));
    } else {
      shopBuy = createShopPlanBuy(self, self.get('creditCard'));
    }

    var customer = JSON.stringify(self.get('customerStorageManagerService').get());
    var authToken = self.get('authTokenService').get();
    try {
      self.set('paymentLoading', true);
    } catch (ex) {} finally {

      if (authToken !== '' && authToken !== null && authToken !== undefined) {
        Ember.$.ajax({
          url: _environment.default.APP.cfwhatsServiceUrl + '/customers',
          type: 'PUT',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': authToken
          },
          data: customer,
          success: function success(response) {
            if (self.get('cart.credits') === true || self.get('cart.autoresponder') === true || self.get('cart.machine') === true || self.get('cart.plan') === true) {
              Ember.$.ajax({
                url: _environment.default.APP.cfwhatsServiceUrl + '/shopping/cart',
                type: 'POST',
                dataType: 'json',
                async: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                data: shopBuy,
                success: function success(response) {
                  self.set('paymentLoading', false);
                  self.set('response', response);
                  // se já existe um pedido ativo para este cliente
                  if (self.get('response.code') !== 0) {
                    if (self.get('response.code') === 172) {
                      self.$("#paymentInfoModal").modal('show');
                      if (self.get('cart.plan') === true) {
                        self.set('response.message', 'Você já possui uma assinatura de plano em vigência para essa conta.');
                      } else {
                        self.set('response.message', 'Você não pode comprar créditos para um pedido que não está ativo.');
                      }
                    } else {
                      self.$("#paymentInfoModal").modal('show');
                      self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                    }
                  } else {
                    self.get('routing').transitionTo('app.success');
                  }
                },
                failure: function failure(response) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('paymentLoading', false);
                  self.set('response', response);
                },
                error: function error(response) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('paymentLoading', false);
                  self.set('response', response);
                }
              });
            } else {
              Ember.$.ajax({
                url: _environment.default.APP.sgplServiceUrl + '/orders/shop',
                type: 'POST',
                dataType: 'json',
                async: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                data: shopBuy,
                success: function success(response) {
                  self.set('paymentLoading', false);
                  self.set('response', response);
                  // se já existe um pedido ativo para este cliente
                  if (self.get('response.code') == 21) {
                    self.$("#paymentInfoModal").modal('show');
                    self.set('response.message', 'Seu pagamento não pode ser processado pois já existe um pedido em vigor para este cliente.');
                  } else {
                    // se for diferente de erro de processamento do pagamento significa que foi finalizado com sucesso.
                    // 8888 e 8881 = erro de pagamento na cielo
                    if (self.get('response.code') !== 8888 && self.get('response.code') !== 8881) {
                      self.get('routing').transitionTo('app.success');
                    } else if (self.get('response.code') === 8881) {
                      self.$("#paymentInfoModal").modal('show');
                      self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                    } else if (self.get('response.code') === 8888) {
                      self.$("#paymentInfoModal").modal('show');
                      self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                    } else {
                      self.$("#paymentInfoModal").modal('show');
                      self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                    }
                  }
                },
                failure: function failure(response) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('paymentLoading', true);
                  self.set('response', response);
                },
                error: function error(response) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('paymentLoading', true);
                  self.set('response', response);
                }
              });
            }
          },
          failure: function failure(response) {
            console.log(response);
            self.set('paymentLoading', false);
            console.log('não atualizou o user');
          },
          error: function error(response) {
            console.log(response);
            self.set('paymentLoading', false);
            console.log('não atualizou o user');
          }
        });
      } else {
        if (self.get('cart.credits') === true || self.get('cart.autoresponder') === true || self.get('cart.machine') === true || self.get('cart.plan') === true) {
          Ember.$.ajax({
            url: _environment.default.APP.cfwhatsServiceUrl + '/shopping/cart',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: shopBuy,
            success: function success(response) {
              self.set('paymentLoading', false);
              self.set('response', response);
              // se já existe um pedido ativo para este cliente
              if (self.get('response.code') !== 0) {
                if (self.get('response.code') === 172) {
                  self.$("#paymentInfoModal").modal('show');
                  if (self.get('cart.plan') === true) {
                    self.set('response.message', 'Você já possui uma assinatura de plano em vigência para essa conta.');
                  } else {
                    self.set('response.message', 'Você não pode comprar créditos para um pedido que não está ativo.');
                  }
                } else {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                }
              } else {
                self.get('routing').transitionTo('app.success');
              }
            },
            failure: function failure(response) {
              self.$("#paymentInfoModal").modal('show');
              self.set('paymentLoading', false);
              self.set('response', response);
            },
            error: function error(response) {
              self.$("#paymentInfoModal").modal('show');
              self.set('paymentLoading', false);
              self.set('response', response);
            }
          });
        } else {
          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/orders/shop',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: shopBuy,
            success: function success(response) {
              self.set('paymentLoading', false);
              self.set('response', response);
              // se já existe um pedido ativo para este cliente
              if (self.get('response.code') == 21) {
                self.$("#paymentInfoModal").modal('show');
                self.set('response.message', 'Seu pagamento não pode ser processado pois já existe um pedido em vigor para este cliente.');
              } else {
                // se for diferente de erro de processamento do pagamento significa que foi finalizado com sucesso.
                // 8888 e 8881 = erro de pagamento na cielo
                if (self.get('response.code') !== 8888 && self.get('response.code') !== 8881) {
                  self.get('routing').transitionTo('app.success');
                } else if (self.get('response.code') === 8881) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                } else if (self.get('response.code') === 8888) {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                } else {
                  self.$("#paymentInfoModal").modal('show');
                  self.set('response.message', 'PAGAMENTO NÃO AUTORIZADO - A operação não foi concluída devido algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora de cartão.');
                }
              }
            },
            failure: function failure(response) {
              self.$("#paymentInfoModal").modal('show');
              self.set('paymentLoading', true);
              self.set('response', response);
            },
            error: function error(response) {
              self.$("#paymentInfoModal").modal('show');
              self.set('paymentLoading', true);
              self.set('response', response);
            }
          });
        }
      }
    }
  };

  var executeFinishStep2 = function executeFinishStep2(self) {
    self.set('step1', false);
    self.set('step2', false);
    self.set('step3', true);

    var customer = self.get('customerStorageManagerService').get();
    customer.street = self.get('customer.street');
    customer.district = self.get('customer.district');
    customer.city = self.get('customer.city');
    customer.state = self.get('customer.state');
    customer.country = self.get('customer.country');
    customer.number = self.get('customer.number');
    customer.complement = self.get('customer.complement');
    customer.postalCode = self.get('customer.postalCode');
    customer.email = self.get('customerStorageManagerService').getSelectedEmail();

    self.get('customerStorageManagerService').store(customer);
  };

  var executeFinishStep1 = function executeFinishStep1(self) {
    if (self.get('releaseStep1ContinueButton')) {
      //habilita o botão de confirmação de email
      self.set('emailConfirmationDisable', false);

      self.get('customerStorageManagerService').store({ 'id': self.get('customer.id'),
        'fullName': self.get('customer.fullName'),
        'cpf': self.get('unmaskedCpf'),
        'phone': self.get('unmaskedPhone'),
        'business': self.get('customer.business'),
        'companyName': self.get('customer.companyName'),
        'cnpj': self.get('unmaskedCnpj'),
        'ie': self.get('customer.ie'),
        'newsletter': true
      });

      $('input#email').focus();
      self.set('step1', false);
      self.set('step2', true);
    }
  };

  var validateCNPJ = function validateCNPJ(c) {

    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    if ((c = c.replace(/[^\d]/g, "")).length != 14) return false;

    if (/0{14}/.test(c)) return false;

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]) {}
    if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]) {}
    if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

    return true;
  };

  var validateCPF = function validateCPF(cpf) {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11) return false;
    for (i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) != cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    }if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i;
      }resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) return false;
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i;
      }resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) return false;
      return true;
    } else return false;
  };

  var createShopPlanBuy = function createShopPlanBuy(self, creditCard) {
    var cartItem = self.get('cartStorageManagerService').get().itens[0];
    var customer = JSON.stringify(self.get('customerStorageManagerService').get());
    var creditCard = JSON.stringify(creditCard);
    var shopPlanBuy = '{"customer":' + customer + ', "cartKey": "' + cartItem.cartKey + '", "creditCard":' + creditCard + '}';
    return shopPlanBuy;
  };

  var createShopCreditBuy = function createShopCreditBuy(self, creditCard) {
    var cartItem = self.get('cartStorageManagerService').get().itens[0];
    var creditCard = JSON.stringify(creditCard);

    var shopCreditBuy = '{"cartKey":"' + cartItem.cartKey + '","creditCard":' + creditCard + '}';
    return shopCreditBuy;
  };

  var ccBrandDetect = function ccBrandDetect(self, num) {
    var card, j, len;
    var defaultFormat = /(\d{1,4})/g;
    var cards = [{
      type: 'amex',
      pattern: /^3[47]/,
      format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
      length: [15],
      cvcLength: [4],
      luhn: true
    }, {
      type: 'dankort',
      pattern: /^5019/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'hipercard',
      pattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/,
      format: defaultFormat,
      length: [14, 15, 16, 17, 18, 19],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'diners',
      pattern: /^(36|38|30[0-5])/,
      format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
      length: [14],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'discover',
      pattern: /^(6011|65|64[4-9]|622)/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'jcb',
      pattern: /^35/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'laser',
      pattern: /^(6706|6771|6709)/,
      format: defaultFormat,
      length: [16, 17, 18, 19],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'maestro',
      pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
      format: defaultFormat,
      length: [12, 13, 14, 15, 16, 17, 18, 19],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'master',
      pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'unionpay',
      pattern: /^62/,
      format: defaultFormat,
      length: [16, 17, 18, 19],
      cvcLength: [3],
      luhn: false
    }, {
      type: 'visaelectron',
      pattern: /^4(026|17500|405|508|844|91[37])/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'elo',
      pattern: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|3[0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8])|9([2-6][0-9]|7[0-8])|541|700|720|901)|651652|655000|655021)/,
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'visa',
      pattern: /^4/,
      format: defaultFormat,
      length: [13, 16, 19],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'aura',
      pattern: /^50/,
      format: defaultFormat,
      length: [13, 16, 19],
      cvcLength: [3],
      luhn: true
    }];

    num = (num + '').replace(/\D/g, '');
    for (j = 0, len = cards.length; j < len; j++) {
      card = cards[j];
      if (card.pattern.test(num)) {
        if (card.type === 'visaelectron' || card.type === 'unionpay' || card.type === 'maestro' || card.type === 'laser' || card.type === 'dinersclub' || card.type === 'hipercard' || card.type === 'dankort') {
          if (num.length > 5) {
            self.set('creditCard.cardNumber', '');
            self.$("#creditCardBrandsModal").modal('show');
          }
        }
        self.set('creditCard.cardNumber', num);
        return card;
      }
    }
  };

  var releaseStep1ContinueButtonVerifyer = function releaseStep1ContinueButtonVerifyer(self) {
    var fullName = self.get('customer.fullName');
    var cpf = self.get('customer.cpf');
    var phone = self.get('customer.phone');
    var business = self.get('isCustomerBusiness');
    var companyName = self.get('customer.companyName');
    var cnpj = self.get('customer.cnpj');
    var ie = self.get('customer.ie');

    self.set('customer.business', self.get('isCustomerBusiness'));
    if (self.get('validFullName') === true && self.get('validCpf') === true && self.get('validPhone') === true && self.get('isCustomerBusiness') === false) {
      self.set('releaseStep1ContinueButton', true);
      self.set('showConfirmationSelectedEmail', true);
      self.set('emailConfirmationDisable', false);
    } else {
      if (self.get('validFullName') === true && self.get('validCpf') === true && self.get('validPhone') === true && self.get('validCompanyName') === true && self.get('validCnpj') === true && self.get('validIe') === true && self.get('isCustomerBusiness') === true) {
        self.set('releaseStep1ContinueButton', true);
        self.set('showConfirmationSelectedEmail', true);
        self.set('emailConfirmationDisable', false);
      } else {
        self.set('releaseStep1ContinueButton', false);
        self.set('emailConfirmationDisable', true);
      }
    }
  };

  var releaseStep2ContinueButtonVerifyer = function releaseStep2ContinueButtonVerifyer(self) {
    var email = self.get('customer.email');
    var postalCode = self.get('customer.postalCode');
    var street = self.get('customer.street');
    var district = self.get('customer.district');
    var city = self.get('customer.city');
    var state = self.get('customer.state');
    var number = self.get('customer.number');

    if (postalCode !== null && postalCode !== undefined && postalCode !== '' && street !== null && street !== undefined && street !== '' && district !== null && district !== undefined && district !== '' && city !== null && city !== undefined && city !== '' && state !== null && state !== undefined && state !== '' && state !== number && state !== number && number !== '') {
      self.set('releaseStep2ContinueButton', true);
      self.set('showPaymentMethods', true);
    } else {
      self.set('releaseStep2ContinueButton', false);
    }
  };

  var releaseStep3ContinueButtonVerifyer = function releaseStep3ContinueButtonVerifyer(self) {
    var number = self.get('creditCard.cardNumber');
    var name = self.get('creditCard.holder');
    var expiration = self.get('creditCard.expirationDate');
    var cvc = self.get('creditCard.securityCode');

    if (number !== null && number !== undefined && number !== '' && name !== null && name !== undefined && name !== '' && expiration !== null && expiration !== undefined && expiration !== '' && cvc !== null && cvc !== undefined && cvc !== '') {
      self.set('releaseStep3ContinueButton', true);
    } else {
      self.set('releaseStep3ContinueButton', false);
    }
  };

  var findCorreioAddress = function findCorreioAddress(self) {
    self.set('unvalidatedCep', false);
    if (self.get('customer.postalCode') !== undefined && self.get('customer.postalCode') !== '' && self.get('customer.postalCode') !== null && Object.keys(self.get('customer.postalCode')).length === 8) {
      Ember.$.ajax({
        url: 'https://viacep.com.br/ws/' + self.get('customer.postalCode') + '/json/',
        type: 'get',
        dataType: 'json',
        crossDomain: true,
        success: function success(res) {
          if (res.erro === true) {
            self.set('validCep', false);
            self.set('customer.postalCode', '');
            self.set('showNumberAndComplement', false);
            self.$('input#postalCode').focus();
          } else {
            self.set('validCep', true);
            self.set('customer.street', res.logradouro);
            self.set('customer.district', res.bairro);
            self.set('customer.city', res.localidade);
            self.set('customer.state', res.uf);
            self.set('customer.country', 'Brasil');
            self.$('input#number').focus();
          }
        }
      });
    }
  };

  exports.default = Ember.Component.extend({
    requestService: Ember.inject.service('request-service'),
    cartStorageManagerService: Ember.inject.service('cart-storage-manager-service'),
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),
    emailVerificationService: Ember.inject.service('email-verification-service'),
    authTokenService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),
    shopService: Ember.inject.service('shop-service'),

    //controle do input q está ao pressinar enter
    inputValueEnterPressed: null,

    didInsertElement: function didInsertElement() {
      //limpando os parametros da url pra ficar bonito uma vez que o checkout já foi instanciado
      window.history.replaceState({}, document.title, "/app/" + "cfwhats/checkout");
      //dando focus no campo de nome ao iniciar a pagina
      this.$('input#fullName').focus();
      this.$('input#creditCardNumber').focus();
    },

    //etapas do checkout
    step1: true,
    step2: false,
    step3: false,

    //endereço habilitado para edição?
    isEditAddress: false,

    //liberar botão CONTINUAR quando uma etapa estiver cumprida
    releaseStep1ContinueButton: false,
    releaseStep2ContinueButton: false,
    releaseStep3ContinueButton: false,

    //flags
    isCustomerBusiness: false,
    isConfirmedSelectedEmail: false,
    isCofirmedSelectedEmailOut: false,
    showNumberAndComplement: false,
    showConfirmationSelectedEmail: false,
    showPaymentMethods: false,
    loading: false,

    //cartão
    months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    ccSelectMonth: null,
    years: ['18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37'],
    ccSelectYear: null,

    //validations
    unvalidatedFullName: true,
    validFullName: false,

    unvalidatedCpf: true,
    validCpf: false,

    unvalidatedPhone: true,
    validPhone: false,

    unvalidatedCompanyName: true,
    validCompanyName: false,

    unvalidatedCnpj: true,
    validCnpj: false,

    unvalidatedIe: true,
    validIe: false,

    unvalidatedCep: true,
    validCep: false,

    unvalidatedNumber: true,
    validNumber: false,

    unvalidatedCardNumber: true,
    validCardNumber: false,

    unvalidatedCardName: true,
    validCardName: false,

    unvalidatedCardCVV: true,
    validCardCVV: false,

    unvalidatedCardMonth: true,
    validCardMonth: false,

    unvalidatedCardYear: true,
    validCardYear: false,

    isValidSelectedEmail: true,

    fullNameClass: "col-lg-12",
    fullNameOkClass: "col-lg-12",
    fullNameErrorClass: "col-lg-12 has-error has-feedback",

    cpfClass: "col-lg-6",
    cpfOkClass: "col-lg-6",
    cpfErrorClass: "col-lg-6 has-error has-feedback",

    phoneClass: "col-lg-6",
    phoneOkClass: "col-lg-6",
    phoneErrorClass: "col-lg-6 has-error has-feedback",

    companyNameClass: "col-lg-12",
    companyNameOkClass: "col-lg-12",
    companyNameErrorClass: "col-lg-12 has-error has-feedback",

    cnpjClass: "col-lg-6",
    cnpjOkClass: "col-lg-6",
    cnpjErrorClass: "col-lg-6 has-error has-feedback",

    ieClass: "col-lg-6",
    ieOkClass: "col-lg-6",
    ieErrorClass: "col-lg-6 has-error has-feedback",

    //cpf valor sem maskara
    unmaskedCpf: null,
    //phone valor sem maskara
    unmaskedPhone: null,
    //cnpj valor sem maskara
    unmaskedCnpj: null,

    //metodo executa ao iniciar o componente
    onStartComponent: Ember.on('init', function () {
      var self = this;
      //muda de campo ao digitar ENTER nos inputs
      $(document).keypress(function (e) {
        var keycode = e.keyCode ? e.keyCode : e.which;
        if (keycode == '13') {
          //campo fullName
          if (self.get('inputValueEnterPressed') == self.get('customer.fullName')) {
            $("input#cpf").focus();
          }

          //campo cpf
          if (self.get('inputValueEnterPressed') == self.get('customer.cpf')) {
            $("input#phone").focus();
          }

          //campo phone          
          if (self.get('inputValueEnterPressed') == self.get('customer.phone')) {
            if (self.get('isCustomerBusiness') == false) executeFinishStep1(self);else $("input#companyName").focus();
          }

          //campo companyName                    
          if (self.get('inputValueEnterPressed') == self.get('customer.companyName')) {
            $("input#cnpj").focus();
          }

          //campo cnpj                    
          if (self.get('inputValueEnterPressed') == self.get('customer.cnpj')) {
            $("input#ie").focus();
          }

          //campo ie         
          if (self.get('inputValueEnterPressed') == self.get('customer.ie')) {
            if (self.get('isCustomerBusiness') == true) executeFinishStep1(self);
          }

          //campo email         
          if (self.get('inputValueEnterPressed') == self.get('customerSelectedEmail')) {
            if (self.get('isConfirmedSelectedEmail') === false) executeConfirmSelectedEmail(self);else $("input#postalCode").focus();
          }

          //campo cep
          if (self.get('inputValueEnterPressed') == self.get('customer.postalCode')) {
            $("input#number").focus();
          }

          //campo number
          if (self.get('inputValueEnterPressed') == self.get('customer.number')) {
            $("input#complement").focus();
          }

          //campo complemento
          if (self.get('inputValueEnterPressed') == self.get('customer.complement')) {
            if (self.get('validNumber') === true && self.get('validCep') === true) executeFinishStep2(self);
          }

          //nro cartao
          if (self.get('inputValueEnterPressed') == self.get('creditCard.cardNumber')) {
            $("input#creditCardName").focus();
          }

          //nro cartao
          if (self.get('inputValueEnterPressed') == self.get('creditCard.holder')) {
            $("input#creditCardCVV").focus();
          }

          //nro cartao
          if (self.get('inputValueEnterPressed') == self.get('creditCard.securityCode')) {
            if (self.get('validCardNumber') === true && self.get('validCardName') === true && self.get('validCardMonth') === true && self.get('validCardYear') === true && self.get('validCardCVV')) {
              executeFinishStep3(self);
            }
          }
        }
      });

      var customerIsLoggedOn = this.get('customerStorageManagerService').getIsLoggedOn();

      var customer = this.get('customerStorageManagerService').get();

      //istancia o tipo do cliente para previsualização no caso de um cliente já existente
      if (customer.business === true || customer.business === false) {
        this.set('isCustomerBusiness', customer.business);
      } else {
        this.set('isCustomerBusiness', false);
      }

      if (customerIsLoggedOn === 'true' || customerIsLoggedOn === true) {
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', true);
        this.set('isConfirmedSelectedEmail', true);
        this.set('customerWithoutRegister', true);
      } else {
        var check = this.get('customerStorageManagerService').getIsLoggedOutWithoutRegister();
        if (check === false) {
          this.set('step1', false);
          this.set('step2', false);
          this.set('step3', true);
          this.set('isConfirmedSelectedEmail', true);
        }
      }
    }),

    cart: function () {
      return this.get('cartStorageManagerService').get();
    }.property('cart'),

    customer: function () {
      return this.get('customerStorageManagerService').get();
    }.property('customer'),

    customerSelectedEmail: function () {
      return this.get('customerStorageManagerService').getSelectedEmail();
    }.property('customerSelectedEmail'),

    customerWithoutRegister: function () {
      this.set('isLog');
      return this.get('customerStorageManagerService').getIsLoggedOutWithoutRegister();
    }.property('customerWithoutRegister'),

    customerIsLoggedOn: function () {
      return this.get('customerStorageManagerService').getIsLoggedOn();
    }.property('customerIsLoggedOn'),

    creditCard: function () {
      var creditCard = {
        cardNumber: "",
        holder: "",
        expirationDate: "",
        securityCode: "",
        brand: ""
      };
      return creditCard;
    }.property('creditCard'),

    //observador do input fullname
    fullNameObserver: Ember.on('init', Ember.observer('customer.fullName', function () {
      if (this.get('customer.fullName') === null || this.get('customer.fullName') === undefined || Object.keys(this.get('customer.fullName')).length > 0) this.set('fullNameClass', this.get('fullNameOkClass'));else this.set('fullNameClass', this.get('fullNameErrorClass'));

      releaseStep1ContinueButtonVerifyer(this);
    })),

    cpfObserver: Ember.on('init', Ember.observer('customer.cpf', function () {
      if (this.get('customer.cpf') === null || this.get('customer.cpf') === undefined || Object.keys(this.get('customer.cpf')).length > 0) this.set('cpfClass', this.get('cpfOkClass'));else this.set('cpfClass', this.get('cpfErrorClass'));

      if (this.get('customer.cpf') !== null && this.get('customer.cpf') !== undefined && Object.keys(this.get('customer.cpf')).length > 13) {
        this.set('unvalidatedCpf', false);
        var vrCpf = this.get('customer.cpf').replace("-", "");
        vrCpf = vrCpf.replace(".", "");
        vrCpf = vrCpf.replace(".", "");
        var validCpf = validateCPF(vrCpf);
        if (validCpf === true) {
          this.set('validCpf', true);
        } else {
          this.set('validCpf', false);
        }
      }

      releaseStep1ContinueButtonVerifyer(this);
    })),

    phoneObserver: Ember.on('init', Ember.observer('customer.phone', function () {
      if (this.get('customer.phone') !== null && this.get('customer.phone') !== undefined) {
        this.set('unmaskedPhone', this.get('utilService').onlyNumber(this.get('customer.phone')));
        this.set('customer.phone', this.get('utilService').phoneMask(this.get('customer.phone')));
      }

      if (this.get('customer.phone') === null || this.get('customer.phone') === undefined || Object.keys(this.get('customer.phone')).length > 0) {
        this.set('phoneClass', this.get('phoneOkClass'));
      } else {
        this.set('phoneClass', this.get('phoneErrorClass'));
      }

      if (this.get('customer.phone') !== null && this.get('customer.phone') !== undefined && Object.keys(this.get('customer.phone')).length >= 14) {
        this.set('validPhone', true);
        this.set('unvalidatedPhone', false);
      } else {
        this.set('validPhone', false);
      }

      releaseStep1ContinueButtonVerifyer(this);
    })),

    businessObserver: Ember.on('init', Ember.observer('customer.business', function () {
      releaseStep1ContinueButtonVerifyer(this);
    })),

    companyNameObserver: Ember.on('init', Ember.observer('customer.companyName', function () {
      if (this.get('customer.companyName') === null || this.get('customer.companyName') === undefined || Object.keys(this.get('customer.companyName')).length > 0) this.set('companyNameClass', this.get('companyNameOkClass'));else this.set('companyNameClass', this.get('companyNameErrorClass'));
      releaseStep1ContinueButtonVerifyer(this);
    })),

    cnpjObserver: Ember.on('init', Ember.observer('customer.cnpj', function () {
      if (this.get('customer.cnpj') === null || this.get('customer.cnpj') === undefined || Object.keys(this.get('customer.cnpj')).length > 0) this.set('cnpjClass', this.get('cnpjOkClass'));else this.set('cnpjClass', this.get('cnpjErrorClass'));

      if (this.get('customer.cnpj') !== null && this.get('customer.cnpj') !== undefined && Object.keys(this.get('customer.cnpj')).length > 17) {
        this.set('unvalidatedCnpj', false);
        var vrCnpj = this.get('customer.cnpj').replace("-", "");
        vrCnpj = vrCnpj.replace(".", "");
        vrCnpj = vrCnpj.replace(".", "");
        vrCnpj = vrCnpj.replace(".", "");
        vrCnpj = vrCnpj.replace("/", "");
        var validCnpj = validateCNPJ(vrCnpj);
        if (validCnpj === true) {
          this.set('validCnpj', true);
        } else {
          this.set('validCnpj', false);
        }
      }

      releaseStep1ContinueButtonVerifyer(this);
    })),

    ieObserver: Ember.on('init', Ember.observer('customer.ie', function () {
      if (this.get('customer.ie') !== null && this.get('customer.ie') !== undefined && this.get('customer.ie') !== '') {
        this.set('unvalidatedIe', false);
      }

      if (this.get('unvalidatedIe') === false) {
        if (this.get('customer.ie') !== null && this.get('customer.ie') !== undefined && this.get('customer.ie') !== '') {
          this.set('ieClass', this.get('ieOkClass'));
          this.set('validIe', true);
        } else {
          this.set('ieClass', this.get('ieErrorClass'));
          this.set('validIe', false);
        }
      }
      releaseStep1ContinueButtonVerifyer(this);
    })),

    postalCodeObserver: Ember.on('init', Ember.observer('customer.postalCode', function () {
      //libera o botão de número para setar o focus na próxima ação
      if (this.get('customer.postalCode') !== undefined && this.get('customer.postalCode') !== '' && this.get('customer.postalCode') !== null && Object.keys(this.get('customer.postalCode')).length === 7) {
        this.set('showNumberAndComplement', true);
      }

      if (this.get('customer.postalCode') !== undefined && this.get('customer.postalCode') !== '' && this.get('customer.postalCode') !== null && Object.keys(this.get('customer.postalCode')).length === 8) {
        findCorreioAddress(this);
      }
    })),

    numberObserver: Ember.on('init', Ember.observer('customer.number', function () {
      releaseStep2ContinueButtonVerifyer(this);
    })),

    //liberação do botão de finalzar pagamento observers
    creditCardNumberObserver: Ember.on('init', Ember.observer('creditCard.cardNumber', function () {
      if (ccBrandDetect(this, this.get('creditCard.cardNumber')) !== null && ccBrandDetect(this, this.get('creditCard.cardNumber')) !== undefined) {
        //console.log(ccBrandDetect(this,this.get('creditCard.cardNumber')).type);
        this.set('creditCard.brand', ccBrandDetect(this, this.get('creditCard.cardNumber')).type);
      }
      releaseStep3ContinueButtonVerifyer(this);
    })),

    creditCardNameObserver: Ember.on('init', Ember.observer('creditCard.holder', function () {
      releaseStep3ContinueButtonVerifyer(this);
    })),

    creditCardExpirationObserver: Ember.on('init', Ember.observer('creditCard.expirationDate', function () {
      releaseStep3ContinueButtonVerifyer(this);
    })),

    creditCardCvcObserver: Ember.on('init', Ember.observer('creditCard.securityCode', function () {
      releaseStep3ContinueButtonVerifyer(this);
    })),

    actions: {
      keyUpFullName: function keyUpFullName(e) {
        //seta somente letras ao digitar no campo
        this.set('customer.fullName', this.get('utilService').onlyLetters(this.get('customer.fullName')));
        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      keyUpIe: function keyUpIe(e) {
        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      focusOutNumber: function focusOutNumber() {
        this.set('unvalidatedNumber', false);
        if (this.get('customer.number') !== null && this.get('customer.number') !== undefined && this.get('customer.number') !== '') {
          this.set('validNumber', true);
        } else {
          this.set('validNumber', false);
        }
      },
      focusOutFullName: function focusOutFullName() {
        var er = /[a-z]\s[a-z]/gim;
        this.set('unvalidatedFullName', false);
        if (er.test(this.get('customer.fullName')) === true) {
          this.set('validFullName', true);
          this.set('fullNameClass', this.get('fullNameOkClass'));
        } else {
          this.set('validFullName', false);
          this.set('fullNameClass', this.get('fullNameErrorClass'));
        }

        releaseStep1ContinueButtonVerifyer(this);
      },
      keyUpPressCpf: function keyUpPressCpf(e) {
        this.set('customer.cpf', this.get('utilService').cpfMask(this.get('customer.cpf')));
        this.set('unmaskedCpf', this.get('utilService').onlyNumber(this.get('customer.cpf')));

        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      focusOutCpf: function focusOutCpf() {
        this.set('unvalidatedCpf', false);
        if (validateCPF(this.get('unmaskedCpf')) === true) {
          this.set('validCpf', true);
          this.set('cpfClass', this.get('cpfOkClass'));
        } else {
          this.set('validCpf', false);
          this.set('cpfClass', this.get('cpfErrorClass'));
        }

        releaseStep1ContinueButtonVerifyer(this);
      },
      focusOutCardNumber: function focusOutCardNumber() {
        this.set('unvalidatedCardNumber', false);
        if (this.get('creditCard.cardNumber') !== null && this.get('creditCard.cardNumber') !== undefined && this.get('creditCard.cardNumber') !== '') {
          this.set('validCardNumber', true);
        } else {
          this.set('validCardNumber', false);
        }
      },
      focusOutCardName: function focusOutCardName() {
        this.set('unvalidatedCardName', false);
        if (this.get('creditCard.holder') !== null && this.get('creditCard.holder') !== undefined && this.get('creditCard.holder') !== '') {
          this.set('validCardName', true);
        } else {
          this.set('validCardName', false);
        }
      },
      focusOutCardCVV: function focusOutCardCVV() {
        this.set('unvalidatedCardCVV', false);
        if (this.get('creditCard.securityCode') !== null && this.get('creditCard.securityCode') !== undefined && this.get('creditCard.securityCode') !== '') {
          this.set('validCardCVV', true);
        } else {
          this.set('validCardCVV', false);
        }
      },
      keyUpPhone: function keyUpPhone(e) {
        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      focusOutCompanyName: function focusOutCompanyName() {
        this.set('unvalidatedCompanyName', false);
        if (this.get('customer.companyName') !== null && this.get('customer.companyName') !== undefined && this.get('customer.companyName') !== "") {
          this.set('validCompanyName', true);
          this.set('companyNameClass', this.get('companyNameOkClass'));
        } else {
          this.set('validCompanyName', false);
          this.set('companyNameClass', this.get('companyNameErrorClass'));
        }

        releaseStep1ContinueButtonVerifyer(this);
      },
      keyUpCompanyName: function keyUpCompanyName(e) {
        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      focusOutCnpj: function focusOutCnpj() {
        this.set('unvalidatedCnpj', false);
        if (validateCNPJ(this.get('unmaskedCnpj')) === true) {
          this.set('validCnpj', true);
          this.set('cnpjClass', this.get('cnpjOkClass'));
        } else {
          this.set('validCnpj', false);
          this.set('cnpjClass', this.get('cnpjErrorClass'));
        }

        releaseStep1ContinueButtonVerifyer(this);
      },
      keyUpCnpj: function keyUpCnpj(e) {
        this.set('unmaskedCnpj', this.get('utilService').onlyNumber(this.get('customer.cnpj')));
        this.set('customer.cnpj', this.get('utilService').cnpjMask(this.get('customer.cnpj')));

        // detect enter keypress
        this.set('inputValueEnterPressed', e);

        releaseStep1ContinueButtonVerifyer(this);
      },
      keyUpPostalCode: function keyUpPostalCode(e) {
        this.set('customer.postalCode', this.get('utilService').onlyNumber(this.get('customer.postalCode')));
        this.set('inputValueEnterPressed', e);
      },
      keyUpCreditCardName: function keyUpCreditCardName(e) {
        this.set('creditCard.holder', this.get('utilService').onlyLettersUpperCase(this.get('creditCard.holder')));
        this.set('inputValueEnterPressed', e);
      },
      findPostalCode: function findPostalCode() {
        window.open('http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm');
      },
      keepBuyingLink: function keepBuyingLink() {
        window.location.replace(this.get('cart').keepBuyingLink);
      },
      keepBuyingMachineLink: function keepBuyingMachineLink() {
        //
        window.location.replace(this.get('cart').keepBuyingMachineLink);
      },
      keepBuyingAutoresponderLink: function keepBuyingAutoresponderLink() {
        window.location.replace(this.get('cart').keepBuyingAutoresponderLink);
      },
      keepBuyingCreditsLink: function keepBuyingCreditsLink() {
        window.location.replace(this.get('cart').keepBuyingCreditsLink);
      },
      isCustomerBusiness: function isCustomerBusiness() {
        if (this.get('isCustomerBusiness') === true) {
          this.set('isCustomerBusiness', false);
          this.set('customer.business', false);
        } else {
          this.set('isCustomerBusiness', true);
          this.set('customer.business', true);
        }
      },
      finishStep1: function finishStep1() {
        executeFinishStep1(this);
      },
      finishStep2: function finishStep2() {
        executeFinishStep2(this);
      },
      setStep1: function setStep1() {
        //desabilita o botão de confirmação de email
        this.set('emailConfirmationDisable', true);

        this.set('step1', true);
        this.set('step2', false);
        this.set('step3', false);
      },
      setStep2: function setStep2() {
        this.set('step1', false);
        this.set('step2', true);
        this.set('step3', false);
      },
      setStep3: function setStep3() {
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', true);
      },
      keyUpConfirmSelectedEmail: function keyUpConfirmSelectedEmail(e) {
        this.set('inputValueEnterPressed', e);
      },
      keyUpCpf: function keyUpCpf(e) {
        this.set('inputValueEnterPressed', e);
      },
      keyUpNumber: function keyUpNumber(e) {
        this.set('inputValueEnterPressed', e);
      },
      keyUpComplement: function keyUpComplement(e) {
        this.set('inputValueEnterPressed', e);
      },
      keyUpCreditCardNumber: function keyUpCreditCardNumber(e) {
        this.set('inputValueEnterPressed', this.get('creditCard.cardNumber'));
      },
      keyUpCreditCardDate: function keyUpCreditCardDate(e) {
        this.set('inputValueEnterPressed', e);
      },
      keyUpCreditCardCVV: function keyUpCreditCardCVV(e) {
        this.set('inputValueEnterPressed', e);
      },
      confirmSelectedEmail: function confirmSelectedEmail() {
        executeConfirmSelectedEmail(this);
      },
      editSelectedEmail: function editSelectedEmail() {
        this.set('isCofirmedSelectedEmailOut', false);

        if (this.get('isConfirmedSelectedEmail') === false) this.set('isConfirmedSelectedEmail', true);else this.set('isConfirmedSelectedEmail', false);

        this.$('input#email').focus();
      },
      focusOutEmail: function focusOutEmail() {
        this.set('inputValueEnterPressed', this.get('customerSelectedEmail'));
        this.set('isCofirmedSelectedEmailOut', true);
      },
      focusOutPostalCode: function focusOutPostalCode() {
        findCorreioAddress(this);
      },
      editAddress: function editAddress() {
        this.set('isEditAddress', true);
      },
      finishStep3: function finishStep3() {
        executeFinishStep3(this);
      },
      stealthInfo: function stealthInfo() {
        this.$("#stealthInfoModal").modal('show');
      },
      unlockLoginModal: function unlockLoginModal() {
        this.$('#loginModal').modal('show');
      },
      login: function login() {
        try {
          var response = this.get('authTokenService').login(this, this.get('customerSelectedEmail'), this.get('loginPassword'));
        } catch (err) {
          alert('não foi possível realizar o login');
          this.get('customerStorageManagerService').storeIsLoggedOn(false);
        } finally {
          if (response === null || response === undefined || response === '') {
            alert('Usuário ou senha inválidos.');
          } else {
            var customer = this.get('authTokenService').getLoggedCustomerData(this, response.token);
            this.get('customerStorageManagerService').storeIsLoggedOn(true);
            this.get('customerStorageManagerService').store(customer);
            this.set('customer', customer);
            //setar mascaras
            this.set('unmaskedCpf', customer.cpf);
            this.set('unmaskedPhone', customer.phone);
            this.set('unmaskedCnpj', customer.cnpj);
            this.get('isCustomerBusiness', customer.business);

            this.set('step1', false);
            this.set('step2', true);
            this.set('step3', false);
            this.set('customerWithoutRegister', true);
            this.set('isConfirmedSelectedEmail', true);
            this.set('customerIsLoggedOn', true);
            this.set('isCofirmedSelectedEmailOut', true);
            this.$('#loginModal').modal('hide');
          }
        }
      },
      resetPassword: function resetPassword() {
        this.$('#loginModal').modal('hide');
        this.get('authTokenService').resetPassword(this, this.get('customerSelectedEmail'));
        this.$('#resetPasswordModal').modal('show');
      },
      continueResetPasswordModal: function continueResetPasswordModal() {
        this.$('input#creditCardNumber').focus();
      },
      goToEmailVerification: function goToEmailVerification() {
        this.get('authTokenService').logoff(this);
        this.get('customerStorageManagerService').storeIsLoggedOn(false);
        this.get('authTokenService');
        this.get('routing').transitionTo('app.email-verification');
      },
      selectCreditCardMonth: function selectCreditCardMonth(value) {
        this.set('unvalidatedCardMonth', false);
        if (value !== 'Mês') {
          this.set('validCardMonth', true);
          this.set('ccSelectMonth', value);
          if (this.get('ccSelectYear') !== undefined && this.get('ccSelectYear') !== null && this.get('ccSelectYear') !== '') {
            this.set('creditCard.expirationDate', this.get('ccSelectMonth') + '/20' + this.get('ccSelectYear'));
          }
        } else {
          this.set('creditCard.expirationDate', null);
          this.set('validCardMonth', false);
        }
      },
      selectCreditCardYear: function selectCreditCardYear(value) {
        this.set('unvalidatedCardYear', false);
        if (value !== 'Ano') {
          this.set('validCardYear', true);
          this.set('ccSelectYear', value);
          this.set('creditCard.expirationDate', null);
          this.set('creditCard.expirationDate', this.get('ccSelectMonth') + '/20' + value);
        } else {
          this.set('creditCard.expirationDate', null);
          this.set('validCardYear', false);
        }
      },
      creditCardSecuritCodeModal: function creditCardSecuritCodeModal() {
        this.$('#creditCardSecuritCodeModal').modal('show');
      },
      setNoIe: function setNoIe() {
        this.get('inputValueEnterPressed', "Isento");
        this.set('customer.ie', "");
        this.set('customer.ie', "Isento");
      }
    }
  });
});