define('ember-js-getting-started/services/request-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    request: function request(self, object, path, method, endpoint) {
      Ember.$.ajax({
        url: endpoint + path,
        type: method,
        dataType: 'json',
        async: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: object,
        success: function success(response) {
          self.set('response', response);
        },
        failure: function failure(response) {
          self.set('response', response);
        },
        error: function error(response) {
          self.set('response', response);
        }
      });
      return self.get('response');
    }
  });
});