define('ember-js-getting-started/components/email-verification-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var responseAction = function responseAction(response, self) {
    self.set('customer', null);
    if (response.status === 200 || response.status === undefined) {
      self.set('customer', response);
      if (Object.keys(response).length > 0) {
        self.get('customerStorageManagerService').storeIsLoggedOutWithoutRegister(false);
        $("#isRegisteredUser").modal('show');
      } else {
        self.get('customerStorageManagerService').storeIsLoggedOutWithoutRegister(true);
        self.get('routing').transitionTo('app.checkout');
      }
    }
  };

  exports.default = Ember.Component.extend({
    emailVerificationService: Ember.inject.service('email-verification-service'),
    routing: Ember.inject.service('-routing'),
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),
    utilService: Ember.inject.service('util-service'),

    actions: {
      validate: function validate() {
        if (this.get('email') != undefined && this.get('email').length > 0 && this.get('utilService').isEmail(this.get('email'))) {
          var customer = this.get('emailVerificationService').validate(this.get('email'));
          this.get('customerStorageManagerService').store(customer);
          this.get('customerStorageManagerService').storeSelectedEmail(this.get('email'));
          responseAction(customer, this);
        } else {
          this.set('invalidEmail', true);
        }
      },
      transitionToCheckout: function transitionToCheckout() {
        this.get('routing').transitionTo('app.checkout');
      }
    }
  });
});