define('ember-js-getting-started/routes/app', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var responseAction = function responseAction(response, self) {
    self.set('customer', null);
    if (response.status === 200 || response.status === undefined) {
      self.set('customer', response);
      if (Object.keys(response).length > 0) {
        self.get('customerStorageManagerService').storeIsLoggedOutWithoutRegister(false);
      } else {
        self.get('customerStorageManagerService').storeIsLoggedOutWithoutRegister(true);
        self.set('invalidEmail', true);
        alert('email inválido, usuário não possui registro');
      }
    }
  };

  exports.default = Ember.Route.extend({
    customerStorageManagerService: Ember.inject.service('customer-storage-manager-service'),
    cartStorageManagerService: Ember.inject.service('cart-storage-manager-service'),
    authTokenService: Ember.inject.service('auth-token-service'),
    utilService: Ember.inject.service('util-service'),
    emailVerificationService: Ember.inject.service('email-verification-service'),

    invalidEmail: false,

    model: function model(params) {
      //instanciando o objeto do carrinho

      //var cart = '{"credits":false, "itens": [{"idProductLicense": "80","prodName":"Licença socialtarget - Plano Combo Professional","qt":"1","price":"289,00"}],"paymentDetails":"Assinatura Mensal R$ - 289,00"}';
      //var cart = '{"credits":true, "email":"pedro.warick@ecompany.net.br", "itens": [{"cartKey": "TESTECKT","idOrder": "727","qt":"2000","price":1000.00}]}';
      //var cart = JSON.parse(cart);

      var cart;
      var self = this;

      /*cart = '{"credits":true, "email":"pedro.warick@ecompany.net.br", "itens": [{"cartKey": "TESTECKT","idOrder": "727","qt":"2000","price":1000.00}]}';
      cart = JSON.parse(cart);
       cart.homeLink = "https://connectface.com.br";
      cart.keepBuyingLink = "https://connectface.com.br";
      cart.userPanelLink = "https://cfwhats-development.herokuapp.com/login";
      cart.keepBuyingCreditsLink = "https://cfwhats-development.herokuapp.com/login";*/

      try {

        //TODO remover jsonparse
        cart = params.application_key.split('&cart=')[1];

        Ember.$.ajax({
          url: _environment.default.APP.cfwhatsServiceUrl + '/shopping/cart/' + cart,
          type: 'GET',
          dataType: 'json',
          async: false,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: null,
          success: function success(response) {
            console.log(response);
            //setando urls de configuração para o connectface 
            cart = '{"plan":' + response.plan + ',"machine":' + response.machine + ',"credits":' + response.credits + ',"autoresponder":' + response.autoresponder + ', "email":"' + response.email + '", "homeLink":"' + _environment.default.APP.homeLink + '", "keepBuyingLink":"' + _environment.default.APP.keepBuyingLink + '", "userPanelLink":"' + _environment.default.APP.userPanelLink + '", "keepBuyingCreditsLink":"' + _environment.default.APP.keepBuyingCreditsLink + '", "keepBuyingAutoresponderLink":"' + _environment.default.APP.keepBuyingAutoresponderLink + '", "itens": [{"cartKey": "' + response.cartKey + '","idOrder": "' + response.idOrder + '","qt":"' + response.qt + '","price":' + response.price + '}]}';
            cart = JSON.parse(cart);
          },
          failure: function failure(response) {
            console.log(response);
            alert('houve um erro inesperado');
          },
          error: function error(response) {
            console.log(response);
            alert('houve um erro inesperado');
          }
        });
      } catch (err) {
        console.log(err);
        alert("É necessário instanciar o carrinho");
      } finally {}

      //armazena os dados do carrinho no storage
      self.get('cartStorageManagerService').store(JSON.stringify(cart));

      // simula estado do cliente no sistema (sem integração com api para carrinho estado sempre deverá ser false)
      self.get('customerStorageManagerService').storeIsLoggedOn(false);

      //recupera o estado do cliente no sistema
      var customerIsLoggedOn = self.get('customerStorageManagerService').getIsLoggedOn();

      //okok
      if (cart.credits === true || cart.autoresponder === true || cart.machine === true) {
        if (cart.email != undefined && cart.email.length > 0 && self.get('utilService').isEmail(cart.email)) {

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/customers/' + cart.email + '/validate',
            type: 'GET',
            dataType: 'json',
            async: false,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: null,
            success: function success(response) {
              var customer = response;
              self.get('customerStorageManagerService').store(customer);
              self.get('customerStorageManagerService').storeSelectedEmail(cart.email);
              responseAction(customer, self);
            },
            failure: function failure(response) {
              console.log(response);
              alert('houve um erro inesperado');
            },
            error: function error(response) {
              console.log(response);
              alert('houve um erro inesperado');
            }
          });
        } else {
          self.set('invalidEmail', true);
          alert('email inválido');
        }
      }

      if ((cart.credits === true || cart.autoresponder === true || cart.machine === true) && self.get('invalidEmail') === false) {
        self.transitionTo('app.checkout');
      } else {
        //se o usuário estiver logado define a rota para onde vai
        if (customerIsLoggedOn === 'true' || customerIsLoggedOn === true) {
          self.transitionTo('app.checkout');
        } else {
          self.get('authTokenService').logoff(self);
          self.transitionTo('app.email-verification');
        }
      }
    }
  });
});